var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "compensation-card" }, [
    _c("div", { staticClass: "compensation-card__main" }, [
      _c("div", { staticClass: "fw500 flex bet a-center" }, [
        _c("div", {
          domProps: { textContent: _vm._s("#" + _vm.data.compensation.id) },
        }),
        _c(
          "button",
          {
            class: { isOpened: _vm.isOpened },
            on: {
              click: function ($event) {
                _vm.isOpened = !_vm.isOpened
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "icon-arr",
                class: { isOpened: _vm.isOpened },
                attrs: {
                  width: "14",
                  height: "8",
                  viewBox: "0 0 14 8",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M6.99996 7.99211C6.74905 7.99211 6.49818 7.89631 6.30688 7.70511L0.287198 1.68536C-0.0957326 1.30243 -0.0957326 0.681576 0.287198 0.2988C0.669973 -0.0839751 1.29071 -0.0839751 1.67367 0.2988L6.99996 5.6254L12.3263 0.298986C12.7092 -0.0837891 13.3299 -0.0837891 13.7126 0.298986C14.0957 0.681762 14.0957 1.30262 13.7126 1.68555L7.69304 7.70529C7.50165 7.89653 7.25078 7.99211 6.99996 7.99211Z",
                    fill: "#4A4A4A",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "compensation-card__main_main",
          on: { click: _vm.data.click },
        },
        [
          _c("div", { staticClass: "compensation-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Client")) },
            }),
            _c("div", {
              domProps: {
                textContent: _vm._s(_vm.data.compensation.client_name),
              },
            }),
          ]),
          _c("div", { staticClass: "compensation-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Phone")) },
            }),
            _c("div", {
              domProps: {
                textContent: _vm._s(_vm.data.compensation.client_phone),
              },
            }),
          ]),
          _c("div", { staticClass: "compensation-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Compensation")) },
            }),
            _vm.data.compensation.type
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.data.compensation.amount + " " + _vm.$t("Points")
                    ),
                  },
                })
              : _vm.data.compensation.gift_ids.length
              ? _c("div", [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.data.compensation.gift_ids[0][
                          _vm.he ? "he" : "en"
                        ] + " "
                      ),
                    },
                  }),
                  _vm.data.compensation.gift_ids.length > 1
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("and more ") +
                              (_vm.data.compensation.gift_ids.length - 1)
                          ),
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "compensation-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    _vm.data.compensation.redeemed_at
                      ? "Redeemed at"
                      : "Created at"
                  )
                ),
              },
            }),
            _c("div", {
              domProps: {
                textContent: _vm._s(
                  _vm.filters.clockTimeDate(
                    _vm.data.compensation.redeemed_at ||
                      _vm.data.compensation.created_at
                  )
                ),
              },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpened,
              expression: "isOpened",
            },
          ],
          staticClass: "compensation-card__main_main",
          style: {
            display: "flex",
            "justify-content": "space-around",
            width: "100%",
            "margin-top": "10px",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                width: "100%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "compensation-card__main_name",
                      style: { width: "50%" },
                    },
                    [
                      _c("div", {
                        staticClass: "grey",
                        domProps: { textContent: _vm._s(_vm.$t("Create by")) },
                      }),
                      _c("div", {
                        domProps: {
                          textContent: _vm._s(_vm.data.compensation.created_by),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "compensation-card__main_name",
                      style: {
                        display: "flex",
                        "justify-content": "center",
                        width: "50%",
                      },
                    },
                    [
                      _c("div", {
                        class: [
                          "status-pending",
                          "status-active",
                          "status-redeemed",
                          "status-rejected",
                        ][_vm.data.compensation.status],
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              [
                                "Pending approval",
                                "Active",
                                "Redeemed",
                                "Rejected",
                              ][_vm.data.compensation.status]
                            )
                          ),
                        },
                      }),
                      _c("div", { staticClass: "flex a-center j-end" }),
                    ]
                  ),
                ]
              ),
              _vm.data.compensation.status == 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        width: "100%",
                        "align-items": "center",
                        "margin-top": "20px",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "compensation-card__main_name",
                          style: { width: "50%" },
                        },
                        [
                          _c("button", {
                            staticClass: "btn",
                            staticStyle: {
                              "min-width": "100px",
                              "max-width": "120px",
                              height: "2.5rem",
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t("Approve")),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.approve_compensation(
                                  _vm.data.compensation
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "compensation-card__main_name",
                          style: { width: "50%" },
                        },
                        [
                          _c("button", {
                            staticClass: "btn btn_border",
                            staticStyle: {
                              "min-width": "100px",
                              "max-width": "120px",
                              height: "2.5rem",
                            },
                            domProps: { textContent: _vm._s(_vm.$t("Cancel")) },
                            on: {
                              click: function ($event) {
                                return _vm.cancel_compensation(
                                  _vm.data.compensation
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }